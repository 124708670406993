import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination"; // Import pagination styles
import Heading from "../../components/common/Typography/Heading";
import ReadMore from "../../components/common/MainButton/ReadMore";
import Paragraph from "../../components/common/Typography/Paragraph";

const BigSlidesSlider = ({ data, lastButton }) => {
  const swiperRef = useRef(null);

  return (
    <>
      <div className="mx-[5%] xl:mx-0 xl:w-full mt-7 sm:mt-10 lg:mt-20">
        <Swiper
          ref={swiperRef}
          className="w-auto xl:w-full"
          breakpoints={{
            300: {
              initialSlide: 0,
              slidesPerView: 1,
            },
            1024: {
              initialSlide: 0,
              slidesPerView: 1,
            },
            1280: {
              initialSlide: 1,
              slidesPerView: "auto",
            },
          }}
          // slidesPerView={"auto"}
          centeredSlides={true}
          spaceBetween={32}
          loop={true}
          speed={1000}
          autoplay={{
            delay: 3000, // Increased delay for better viewing
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          // onBeforeInit={(swiper) => {
          //   swiperRef.current = swiper;
          // }}
        >
          <>
            {/* <SwiperSlide className="hidden xl:block xl:w-[1100px] 2xl:w-[1264px] cursor-pointer h-[350px] md:h-[400px] lg:h-[500px] xl:h-[600px] 2xl:h-[700px] 2k:h-[800px] 4k:h-[900px] xl:mr-8 2xl:mr-8 rounded-3xl bg-pastalblue "></SwiperSlide> */}
            {data?.map((e, index) => (
              <SwiperSlide
                key={index}
                className="w-full xl:w-[1100px] 2xl:w-[1264px] aspect-[1264px/700px] rounded-2xl"
              >
                <div className="w-full xl:w-[1100px] 2xl:w-[1264px] cursor-pointer h-auto relative rounded-3xl">
                  <div className="absolute w-full z-10 rounded-3xl">
                    <div className="flex flex-col mt-10 mx-[5%]">
                      <Heading
                        variant={"h6"}
                        className={"text-white max-w-[700px]"}
                      >
                        {e.rareHeading}
                      </Heading>
                      {e.subHeading ? (
                        <Heading
                          variant={"h6"}
                          className={"text-white max-w-[700px]"}
                        >
                          {e.subHeading}&nbsp;
                        </Heading>
                      ) : e.para ? (
                        <Heading
                          variant={"h6"}
                          className={"text-white max-w-[700px] mt-3"}
                        >
                          {e.para}
                        </Heading>
                      ) : (
                        ""
                      )}
                      {/* {e.navigationText && (
                        <div>
                          <ReadMore
                            variant={"link"}
                            text={e.buttonText}
                            link={e.link}
                            mainClass={e.class}
                            groupHoverWidthClasses={e.subClass}
                          />
                        </div>
                      )} */}
                    </div>
                  </div>
                  <img
                    className="h-full w-full object-cover rounded-3xl opacity-90 aspect-[1264px/700px]"
                    src={require("../../../src/assets/img/demo.jpg")}
                    alt="/"
                  />
                </div>
              </SwiperSlide>
            ))}
          </>
        </Swiper>
      </div>
      <div className="mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px]">
        <div className="flex justify-center mt-5 lg:mt-10">
          {lastButton && (
            <ReadMore
              variant={"link"}
              text={"See how tradesposter works"}
              mainClass={
                "w-[180px] sm:w-[180px] md:w-[200px] lg:w-[190px] xl:w-[190px] 2xl:w-[205px] text-offblack"
              }
              groupHoverWidthClasses={
                "group-hover:w-[180px] sm:group-hover:w-[185px] md:group-hover:w-[205px] lg:group-hover:w-[195px] xl:group-hover:w-[195px] 2xl:group-hover:w-[210px]"
              }
              // link={"/how-it-works"}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default BigSlidesSlider;
