import React, { useEffect, useRef } from "react";

import { useState } from "react";
import SpecailStars from "../RatingStars/SpecailStars";
// import Popup from "../../popup/Popup";
// import FormDesign from "./FormDesign";
import Heading from "../Typography/Heading";

const ReviewSubmissionForm = () => {
  // const [form, setForm] = useState(false);
  // const [ShowCrossButton, setShowCrossButton] = useState(true);
  // useEffect(() => {
  //   if (form) {
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.body.style.overflow = "visible";
  //     document.body.style.width = `auto`;
  //   }
  // }, [form]);

  return (
    <>
      {/* Review Opener */}
      <div className="flex justify-center">
        <div className="flex flex-col w-full md:w-[468px]">
          <Heading
            variant={"h6"}
            className={"flex justify-center text-offblack uppercase md:normal-case"}
          >
            Leave a review
          </Heading>
          <div className="flex justify-center mt-3">
            {/* <span onClick={() => setForm(!form)}> */}
            <span>
              <SpecailStars rating={5} className={"size-5 sm:size-4"} />
            </span>
          </div>
        </div>
      </div>
      {/* Review Form */}
      {/* {form && (
        <Popup
          cross={true}
          setTrigger={setForm}
          ShowCrossButton={ShowCrossButton}
          onClose={() => setForm(false)}
        >
          <FormDesign closePopup={() => setForm(false)} />
        </Popup>
      )} */}
    </>
  );
};

export default ReviewSubmissionForm;
