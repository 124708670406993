import { AutoComplete } from "primereact/autocomplete";
import { useState } from "react";
import { useSelector } from "react-redux";
import { createSearchParams, useNavigate, useLocation } from "react-router-dom";
import "./style.css";
import AuxiliaryText from "../Typography/AuxiliaryText";
export default function SearchInput({ className, ...restProps }) {
  const [searchData, setSearchData] = useState(null);
  const [filterCategory, setFilterCategory] = useState(null);
  const location = useLocation();

  const handleChange = (e) => {
    setSearchData(e.target.value);
  };
  const businessCategories = useSelector(
    (category) => category.BusinessCategoryReducer
  );
  const searchCategory = (event) => {
    setTimeout(() => {
      let _filterCategory;
      if (!event.query.trim().length) {
        _filterCategory = [...businessCategories];
      } else {
        _filterCategory = businessCategories.filter((category) => {
          return category.businessCategoryName
            .toLowerCase()
            .startsWith(event.query.toLowerCase());
        });
      }

      setFilterCategory(_filterCategory);
    }, 250);
  };
  const navigate = useNavigate();
  return (
    <>
    <div>
      <label className={`block mb-2`}>
        <AuxiliaryText variant={"FieldLabel"}>Keywords</AuxiliaryText>
      </label>
      <div
        className={
          location?.pathname?.includes("Services")
            ? "relative overflow-hidden w-full h-[46px] sm:h-[50px] md:h-[54px] lg:h-[56px] xl:w-[420px] xl:h-[56px] 2xl:w-[500px] 2xl:h-[56px] 2xxl:w-[700px] rounded-lg placeholder-lightgray"
            : "relative overflow-hidden w-full h-[46px] sm:h-[50px] md:h-[54px] lg:h-[56px] xl:w-[420px] xl:h-[56px] 2xl:w-[500px] 2xl:h-[56px] 2xxl:w-[700px] rounded-lg placeholder-lightgray"
        }
      >
        <AutoComplete
          className={
            location?.pathname?.includes("Services")
              ? "font-ptsans font-normal normal-case text-[11px] leading-[1.4] tracking-[0px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px] w-full h-[46px] sm:h-[50px] md:h-[54px] lg:h-[56px] xl:w-[420px] xl:h-[56px] 2xl:w-[500px] 2xl:h-[56px] 2xxl:w-[700px]"
              : "font-ptsans font-normal normal-case text-[11px] leading-[1.4] tracking-[0px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px] w-full h-[46px] sm:h-[50px] md:h-[54px] lg:h-[56px] xl:w-[420px] xl:h-[56px] 2xl:w-[500px] 2xl:h-[56px] 2xxl:w-[700px]"
          }
          placeholder={
            location?.pathname?.includes("Services")
              ? "Effortlessly find the services! "
              : "Need a service now? "
          }
          value={searchData}
          suggestions={filterCategory}
          completeMethod={searchCategory}
          field="businessCategoryName"
          onChange={(e) => setSearchData(e.value)}
          aria-label="Categories"
          dropdownAriaLabel="Select Category"
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              if (typeof searchData === "object" && searchData) {
                navigate({
                  pathname: "/Services",
                  search: `?${createSearchParams({
                    id: searchData?.id,
                  })}`,
                });
              }
            }
          }}
        />
        <button
          className={
            location?.pathname?.includes("Services")
              ? "h-[50px] sm:h-[50px] md:h-[54px] lg:h-[54px] xl:h-[56px] 2xl:h-[56px] flex justify-center items-center group group-hover:cursor-pointer mr-5 absolute right-0 top-0"
              : "h-[50px] sm:h-[50px] md:h-[54px] lg:h-[54px] xl:h-[56px] 2xl:h-[56px] flex justify-center items-center group group-hover:cursor-pointer mr-5 absolute right-0 top-0"
          }
          type="submit"
          onClick={() => {
            if (typeof searchData === "object" && searchData) {
              navigate({
                pathname: "/Services",
                search: `?${createSearchParams({
                  id: searchData?.id,
                })}`,
              });
            }
          }}
        >
          <i
            className={
              "fi fi-rs-search text-base text-offblack flex items-center"
            }
          ></i>
        </button>
      </div>

    </div>
    </>
  );
}
