import React from "react";
// import Hero from "../sections/Protag/Hero";
import LeftText from "../sections/Protag/LeftText";
import RighText from "../sections/Protag/RightText";
import SuccessSlider from "../sections/Protag/SuccessSlider";
import PricingGuide from "../sections/Protag/PricingGuide";
import ImageZoomEffect from "../sections/Protag/ZoomContainer";

const ProTags = () => {
  return (
    <>
      <ImageZoomEffect />
      {/* <Hero /> */}
      <LeftText
        heading={"Stand out with the PRO plan"}
        text={
          "The PRO plan ensures your business always stands out with featured posts that stay at the top of search results, giving you unmatched visibility. Completing and verifying your profile builds trust and helps customers confidently choose you from the crowd."
        }
      />
      <RighText
        heading={"Build trust and engage customers directly"}
        text={
          "When businesses complete their profile with contactable details like mobile, email, and social media, customers can reach out directly to discuss their needs. The PRO plan ensures your business stays visible and accessible, helping you establish trust and build meaningful connections faster."
        }
      />
      <SuccessSlider />
      <PricingGuide />
    </>
  );
};

export default ProTags;
