import React, { useEffect, useRef } from "react";
import MainButton from "../../components/common/MainButton/MainButton";
import Popup from "../../components/popup/Popup";
// import VerificationPopUp from "../VerificationPop/VerificationPopUp";
import { useState } from "react";
import Paragraph from "../../components/common/Typography/Paragraph";
import useClickOutside from "../../Hooks/useClickOutside";
import ReviewReport from "../../components/common/ReportForm/ReviewReport";
import AuxiliaryText from "../../components/common/Typography/AuxiliaryText";
import SpecialText from "../../components/common/Typography/SpecialText";
import { Rating } from "primereact/rating";

const PosterReviews = ({ showMeReply, showMeReport, DropdownClasses }) => {
  const [ShowCrossButton, setShowCrossButton] = useState(true);
  const [report, setReport] = useState(false);
  const [filters, setFilters] = useState(false);
  const ref = useRef(null);
  useClickOutside(ref, () => {
    setFilters(false);
  });
  const [reply, setReply] = useState(false);

  useEffect(() => {
    if (report || reply) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
      document.body.style.width = `auto`;
    }
  }, [report, reply]);
  return (
    <>
      <div className="mt-10 md:mt-20 relative w-fit" ref={ref}>
        <MainButton
          variant={"mediumbetween"}
          onClick={() => setFilters(!filters)}
        >
          Highest
          <i
            className={
              filters
                ? "fi fi-rs-angle-up text-xs lg:text-sm cursor-pointer"
                : "fi fi-rs-angle-down text-xs lg:text-sm cursor-pointer"
            }
          ></i>
        </MainButton>

        {filters && (
          <div
            className={`flex flex-col rounded-xl w-[170px] sm:w-[180px] md:w-[190px] lg:w-60 h-auto gap-3 absolute z-10 mt-3 text-offblack py-5 px-3 ${DropdownClasses}`}
          >
            <button onClick={() => setFilters(false)}>
              <AuxiliaryText
                variant={"MenuDropdown"}
                className={"text-offblack hover:text-primaryblue"}
              >
                Lowest
              </AuxiliaryText>
            </button>
            <button onClick={() => setFilters(false)}>
              <AuxiliaryText
                variant={"MenuDropdown"}
                className={"text-offblack hover:text-primaryblue"}
              >
                Latest
              </AuxiliaryText>
            </button>
            <button onClick={() => setFilters(false)}>
              <AuxiliaryText
                variant={"MenuDropdown"}
                className={"text-offblack hover:text-primaryblue"}
              >
                Earliest
              </AuxiliaryText>
            </button>
          </div>
        )}
      </div>
      <div className="border-b border-b-mediumgray pb-10 mt-10">
        <div className="flex gap-x-3">
          <div className="w-10 sm:w-12 shrink-0">
            <img
              className="size-10 sm:size-12 object-cover rounded-full"
              src={require("../../assets/img/User.png")}
              alt={"no"}
            />
          </div>
          <div className="mt-2">
            <SpecialText variant={"ProfileName"} className={"text-offblack"}>
              {/* {reviewData.reviewBy} */}
              Jozeph J
            </SpecialText>
            <div className="mt-1">
              <SpecialText
                variant={"OccupationLocation"}
                className={"text-start text-offblack"}
              >
                Duffy, Australian Capital Territory, 2611
              </SpecialText>
            </div>
            <div className="mt-3">
              <div>
                <Paragraph variant={"MainParagraph"}>
                  {/* {reviewData.reviewMessage} */}
                  Donec consequat, lacus vel ornare laoreet, nibh lacus blandit
                  est, venenatis feugiat augue augue eu ipsum. a b c Vestibulum
                  pulvinar eleifend facilisis. Aliquam a tempor orci, vitae
                  ullamcorper mauris. Cras sollicitudin turpis quis tempus
                  pretium. Maecenas vehicula mauris sagittis justo blandit, sed
                  consequat lacus dictum. Sed molestie diam neque, ut
                  consectetur dui iaculis vitae. Curabitur suscipit nisi quis
                  odio maximus suscipit. Quisque eu lacus non ex rutrum mollis a
                  quis enim. Fusce sapien est, vulputate
                </Paragraph>

                {report && (
                  <Popup
                    setTrigger={setReport}
                    ShowCrossButton={ShowCrossButton}
                  >
                    <ReviewReport closePopup={() => setReport(false)} />
                  </Popup>
                )}
                <div className="flex items-center h-4 mb-10 mt-5">
                  <div className="flex items-center">
                    <Rating
                      value={3}
                      stars={1}
                      style={{ gap: 5 }}
                      cancel={false}
                      defaultValue={4}
                    />
                    <SpecialText
                      variant={"Counter"}
                      className={"ml-1 text-offblack"}
                    >
                      4.9
                    </SpecialText>
                  </div>
                  <div className="mx-2 pb-1">
                    <i className="fi fi-ss-bullet text-[10px] text-offblack"></i>
                  </div>
                  <div>
                    <SpecialText
                      variant={"Counter"}
                      className={"text-offblack"}
                    >
                      1 hour ago
                    </SpecialText>
                  </div>
                  {!showMeReport && (
                    <>
                      <div className="mx-2">
                        <i className="fi fi-ss-bullet text-[10px] text-offblack"></i>
                      </div>
                      <button
                        className="flex"
                        type="button"
                        onClick={() => setReport(true)}
                      >
                        <SpecialText
                          variant={"Counter"}
                          className={"cursor-pointer hover:text-primaryblue"}
                        >
                          Report
                        </SpecialText>
                        {/* <i className="fi fi-rr-flag-alt text-[10px] cursor-pointer hover:text-alertred ml-2"></i> */}
                      </button>
                    </>
                  )}
                  {/* {!showMeReply && (
                    <>
                      <div className="mx-2">
                        <i className="fi fi-ss-bullet text-[10px] text-offblack"></i>
                      </div>
                      <div onClick={() => setReply(true)}>
                        <SpecialText
                          variant={"Counter"}
                          className={"cursor-pointer hover:text-primaryblue"}
                        >
                          Reply
                        </SpecialText>
                      </div>
                    </>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* {reply && (
          <Popup
            setTrigger={() => setReply(false)}
            ShowCrossButton={ShowCrossButton} 
          >
            <VerificationPopUp
              buttonText={"Submit"}
              closePopup={() => setReply(false)}
              firstMainHeading={"Please write your reply"}
              // label={""}
              placeholder={"Write your reply to customer review"}
              firstP={
                "Please verify your email address to ensure you receive all important notifications and updates from us. We have sent verification code to your provided email."
              }
              secondMainHeading={"Submitted Successfully!"}
              secondP={
                "Congratulations, you've successfully verified your business email. This helps us maintain secure communication and provide you with timely updates about your account."
              }
            />
          </Popup>
        )} */}
        {/* <div className="mt-5 ml-10 md:ml-20">
          <div className="flex gap-x-3">
            <div className="w-10 sm:w-12 shrink-0">
              <img
                className="size-10 sm:size-12 object-cover rounded-full"
                src={require("../../assets/img/User.png")}
                alt={"no"}
              />
            </div>
            <div className="mt-2">
              <SpecialText variant={"ProfileName"} className={"text-offblack"}>
                ABC Plumbing
              </SpecialText>
              <div className="mt-1">
                <SpecialText
                  variant={"OccupationLocation"}
                  className={"text-offblack"}
                >
                  Duffy, Australian Capital Territory, 2611
                </SpecialText>
              </div>

              <div className="mt-3">
                <div>
                  <Paragraph
                    variant={"MainParagraph"}
                    className={"text-offblack"}
                  >
                    Donec consequat, lacus vel ornare laoreet, nibh lacus
                    blandit est, venenatis feugiat augue augue eu ipsum.
                    Vestibulum pulvinar eleifend facilisis. Donec consequat,
                    lacus vel ornare laoreet, nibh lacus blandit est, venenatis
                    feugiat augue augue eu ipsum. Vestibulum pulvinar eleifend
                    facilisis.
                  </Paragraph>
                  <div className="flex items-center h-4 mt-5">
                    <div className="flex items-center">
                      <Rating
                        value={3}
                        stars={1}
                        style={{ gap: 5 }}
                        cancel={false}
                        defaultValue={4}
                      />
                      <SpecialText
                        variant={"Counter"}
                        className={"ml-1 text-offblack"}
                      >
                        4.7
                      </SpecialText>
                    </div>
                    <div className="mx-2">
                      <i className="fi fi-ss-bullet text-[10px] text-offblack"></i>
                    </div>
                    <div>
                      <SpecialText
                        variant={"Counter"}
                        className={"text-offblack"}
                      >
                        1 hour ago
                      </SpecialText>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>

      {/* Second dumy */}
      <div className="border-b border-b-mediumgray pb-10 mt-10">
        <div className="flex gap-x-3">
          <div className="w-10 sm:w-12 shrink-0">
            <img
              className="size-10 sm:size-12 object-cover rounded-full"
              src={require("../../assets/img/User.png")}
              alt={"no"}
            />
          </div>
          <div className="mt-2">
            <SpecialText variant={"ProfileName"} className={"text-offblack"}>
              {/* {reviewData.reviewBy} */}
              Jozeph J
            </SpecialText>
            <div className="mt-1">
              <SpecialText
                variant={"OccupationLocation"}
                className={"text-start text-offblack"}
              >
                Duffy, Australian Capital Territory, 2611
              </SpecialText>
            </div>
            <div className="mt-3">
              <div>
                <Paragraph variant={"MainParagraph"}>
                  {/* {reviewData.reviewMessage} */}
                  Donec consequat, lacus vel ornare laoreet, nibh lacus blandit
                  est, venenatis feugiat augue augue eu ipsum. a b c Vestibulum
                  pulvinar eleifend facilisis. Aliquam a tempor orci, vitae
                  ullamcorper mauris. Cras sollicitudin turpis quis tempus
                  pretium. Maecenas vehicula mauris sagittis justo blandit, sed
                  consequat lacus dictum. Sed molestie diam neque, ut
                  consectetur dui iaculis vitae. Curabitur suscipit nisi quis
                  odio maximus suscipit. Quisque eu lacus non ex rutrum mollis a
                  quis enim. Fusce sapien est, vulputate
                </Paragraph>

                {report && (
                  <Popup
                    setTrigger={setReport}
                    ShowCrossButton={ShowCrossButton}
                  >
                    <ReviewReport closePopup={() => setReport(false)} />
                  </Popup>
                )}
                <div className="flex items-center h-4 mb-10 mt-5">
                  <div className="flex items-center">
                    <Rating
                      value={3}
                      stars={1}
                      style={{ gap: 5 }}
                      cancel={false}
                      defaultValue={4}
                    />
                    <SpecialText
                      variant={"Counter"}
                      className={"ml-1 text-offblack"}
                    >
                      4.9
                    </SpecialText>
                  </div>
                  <div className="mx-2 pb-1">
                    <i className="fi fi-ss-bullet text-[10px] text-offblack"></i>
                  </div>
                  <div>
                    <SpecialText
                      variant={"Counter"}
                      className={"text-offblack"}
                    >
                      1 hour ago
                    </SpecialText>
                  </div>
                  {!showMeReport && (
                    <>
                      <div className="mx-2">
                        <i className="fi fi-ss-bullet text-[10px] text-offblack"></i>
                      </div>
                      <button
                        className="flex"
                        type="button"
                        onClick={() => setReport(true)}
                      >
                        <SpecialText
                          variant={"Counter"}
                          className={"cursor-pointer hover:text-primaryblue"}
                        >
                          Report
                        </SpecialText>
                        {/* <i className="fi fi-rr-flag-alt text-[10px] cursor-pointer hover:text-alertred ml-2"></i> */}
                      </button>
                    </>
                  )}
                  {/* {!showMeReply && (
                    <>
                      <div className="mx-2">
                        <i className="fi fi-ss-bullet text-[10px] text-offblack"></i>
                      </div>
                      <div onClick={() => setReply(true)}>
                        <SpecialText
                          variant={"Counter"}
                          className={"cursor-pointer hover:text-primaryblue"}
                        >
                          Reply
                        </SpecialText>
                      </div>
                    </>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* {reply && (
          <Popup
            setTrigger={() => setReply(false)}
            ShowCrossButton={ShowCrossButton}
            // popupWidth={"w-[350px] h-[500px] sm:w-[600px] sm:h-[500px] 2xl:w-[550px] 2xl:h-[600px] pb-10"}
            //   crossPosition={
            //     "flex justify-end w-[16px] absolute left-[310px] sm:left-[555px] 2xl:left-[505px] top-3"
            //   }
          >
            <VerificationPopUp
              closePopup={() => setReply(false)}
              firstMainHeading={"Please write your reply"}
              // label={""}
              placeholder={"Write your reply to customer review"}
              firstP={
                "Please verify your email address to ensure you receive all important notifications and updates from us. We have sent verification code to your provided email."
              }
              secondMainHeading={"Submitted Successfully!"}
              secondP={
                "Congratulations, you've successfully verified your business email. This helps us maintain secure communication and provide you with timely updates about your account."
              }
              buttonText={"Submit"}
            />
          </Popup>
        )} */}
        {/* <div className="mt-5 ml-10 md:ml-20">
          <div className="flex gap-x-3">
            <div className="w-10 sm:w-12 shrink-0">
              <img
                className="size-10 sm:size-12 object-cover rounded-full"
                src={require("../../assets/img/User.png")}
                alt={"no"}
              />
            </div>
            <div className="mt-2">
              <SpecialText variant={"ProfileName"} className={"text-offblack"}>
                ABC Plumbing
              </SpecialText>
              <div className="mt-1">
                <SpecialText
                  variant={"OccupationLocation"}
                  className={"text-offblack"}
                >
                  Duffy, Australian Capital Territory, 2611
                </SpecialText>
              </div>

              <div className="mt-3">
                <div>
                  <Paragraph
                    variant={"MainParagraph"}
                    className={"text-offblack"}
                  >
                    Donec consequat, lacus vel ornare laoreet, nibh lacus
                    blandit est, venenatis feugiat augue augue eu ipsum.
                    Vestibulum pulvinar eleifend facilisis. Donec consequat,
                    lacus vel ornare laoreet, nibh lacus blandit est, venenatis
                    feugiat augue augue eu ipsum. Vestibulum pulvinar eleifend
                    facilisis.
                  </Paragraph>
                  <div className="flex items-center h-4 mt-5">
                    <div className="flex items-center">
                      <Rating
                        value={3}
                        stars={1}
                        style={{ gap: 5 }}
                        cancel={false}
                        defaultValue={4}
                      />
                      <SpecialText
                        variant={"Counter"}
                        className={"ml-1 text-offblack"}
                      >
                        4.7
                      </SpecialText>
                    </div>
                    <div className="mx-2">
                      <i className="fi fi-ss-bullet text-[10px] text-offblack"></i>
                    </div>
                    <div>
                      <SpecialText
                        variant={"Counter"}
                        className={"text-offblack"}
                      >
                        1 hour ago
                      </SpecialText>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default PosterReviews;
